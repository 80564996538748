import request from '@/utils/request'
// 自身获取预约信息（移动端）
export function home (params) {
  return request({
    url: '/appointment/appointment/get_appointment_info_by_self/',
    method: 'get',
    params,
    baseURL: process.env.VUE_APP_BASE_API_WAI
  })
}

// 工作人员扫码获取预约人员信息（移动端）
export function get_appointment_info (params) {
  return request({
    url: '/appointment/appointment/get_appointment_info_by_worker/',
    method: 'get',
    params
  })
}

// 工作人员为预约人员签到（移动端）
export function sign_appointment (params) {
  return request({
    url: '/appointment/appointment/sign_appointment/',
    method: 'post',
    params
  })
}