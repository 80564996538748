import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import index from '../views/index.vue'
import result from '../views/result.vue'
import guestInfo from '../views/guestInfo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/visit/secret/:userId/',
    // path: '/visit/secret/',
    name: 'index',
    component: index
  },
  {
    path: '/visit/result/:userId',
    name: 'result',
    component: result
  },
  {
    path: '/visit/check/:userId/',
    name: 'guestInfo',
    component: guestInfo
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  // 线上路径
  base: '/apps/mp/',
  // 线上路由模式
  mode:'history',
  routes
})

export default router
