<template>
  <div class="result">
    <h3 class="h3">APPOINTMENT TIME</h3>
    <h1 class="h1">预约时间</h1>
    <div class="time">
      <div class="timeLeft"></div>
      <!-- <div class="timeRight">{{ content.appoint_time }}</div> -->
      <div class="timeRight">
        <p class="p1">{{time1[0]}}/{{time1[1]}}/{{time1[2]}}</p>
        <p class="p1 p2">{{time3?time3:time2[0]}}:{{time2[1]}} {{time2[0] - 12 >=0?"P.M":"A.M"}}</p>
      <!-- 2021/07/28 <br />10:30 A.M -->
      </div>
    </div>
    <!-- <h3>ADDRESS</h3> -->
    <!-- <h1 class="common_h1">数据中心中文名</h1> -->
    <!-- {{ content.engine_room_name_en }} -->
    <!-- <h1 class="common_h1">{{ content.engine_room_name }}</h1> -->
    <div class="address">
      <div class="addressImg">
        <img src="../assets/address.png" alt="" class="addressImg" />
      </div>
      <!-- 广州市黄埔区开发大道玉岩天健实验学校西北侧约70米 -->
      <div class="addressCon">{{ content.engine_room_name }}</div>
    </div>
    <div class="address-box">{{ content.address }}</div>
    <!-- <h1>二维码base64数据</h1> -->
    <!-- {{ content.qr_code }} -->
    <div class="qr_code">
      <div><img :src="content.qr_code" alt="" class="codeImg" /></div>
      <div class="tro">请手动截图保存此页<br/>来访时请出示此二维码</div>
    </div>
  </div>
</template>
<script>
import { home } from '@/api/index'
export default {
  data() {
    return {
      content: "",
      time1:"",
      time2:"",
      time3:""
    }
  },
  mounted() {
    this._home({
      // secret_key: 'pKLIwH',
      secret_key: this.$route.params.userId,
      attach_qr_code: 1,
    })
    // let res = '2022-02-14 11:08:01'
    //  let str1 =  res.substr(0,10)
    //    let str2=  res.substr(11)
    //    this.time1 = str1.split("-")
    //    this.time2 = str2.split(":")
    //     if (this.time2[0]>12) {
    //      let time2_2 =  this.time2[0] - 12
    //      if (time2_2 <10) {
    //        this.time3 = '0' + time2_2
    //      }
    //    }
  },
  methods: {
    _home(prams) {
      home(prams).then((res) => {
        console.log('res');
        console.log(res);
        this.content = res
        // 处理时间
       let str1 =  res.appoint_time.substr(0,10)
       let str2=  res.appoint_time.substr(11)
       this.time1 = str1.split("-")
       this.time2 = str2.split(":")
        if (this.time2[0]>12) {
         let time2_2 =  this.time2[0] - 12
         if (time2_2 <10) {
           this.time3 = '0' + time2_2
         }
       }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>
.result {
  padding-top:87px;
}
.h1 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 8px;
  height: 40px;
  line-height: 40px;
}
h3 {
  font-size: 26px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.25);
  height: 28px;
  line-height: 28px;
}
.code {
  width: 128px;
  height: 128px;
}
.time {
  /* position: relative; */
  display: flex;
  margin-top: 16px;
  margin-bottom: 25px;
}
.timeLeft {
  display: block;
  width: 6px;
  height: 88px;
  background: #65aa7f;
  margin-right: 16px;
}
.timeRight {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.75);
  /* line-height: 38px; */
  letter-spacing:4px;
  font-size: 40px;
}
.p1{
  line-height: 40px;
}
.p2{
  margin-top: 8px;
}
.common_h1 {
  font-size: 24px;
  line-height: 24px;
  margin: 8px 0;
}
.address {
  display: flex;
}
.addressImg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.addressCon {
  width: 299px;
  height: 24px;
  font-size: 24px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
}
.address-box {
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0 90px;
  text-indent: 34px;
}
.qr_code {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.codeImg {
  display: block;
  width: 128px;
  height: 128px;
}
.tro {
  width: 311px;
  height: 128px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-left: 24px;
  font-size: 16px;
  color: #000;
}
</style>