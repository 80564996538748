<template>
<div>
  <div class="guestInfo">
      <div><img src="../assets/guest_info.png" alt="" class="titleImg" /></div>
      <div class="content">
        <p class="common_p">预约单号：<span class="common_span">{{ content.order_no }}</span></p>
        <p class="common_p">来宾姓名：<span class="common_span">{{ content.name }}</span></p>
        <p class="common_p">联系电话：<span class="common_span">{{ content.phone }}</span></p>
        <p class="common_p">证件类型：<span class="common_span">{{ content.certificates_type ===0?"身份证":"护照" }}</span></p>
        <p class="common_p">证件号码：<span class="common_span">{{ content.certificates_no }}</span></p>
        <p class="common_p">车牌号码：<span class="common_span">{{ content.car_no }}</span></p>
      </div>
        <img src="../assets/guest_sign.png" alt="" v-if="content.sign_status"  class="sign1"/>
      <!-- sign_status  0 == 未签到  1 == 签到 -->
    

  </div>
  <div class="configInfo" v-if="!content.sign_status">
    <div @click="_sign_appointment" class="buttonConfig">确认信息</div>
    <p class="configTro">核对信息无误后请工作人员点击确认信息按钮</p>
  </div>
</div>
</template>
<script>
import { get_appointment_info, sign_appointment } from '@/api/index'
export default {
  data() {
    return {
      content: "",
      params: {
        // secret_key: 'pKLIwH',
        secret_key: this.$route.params.userId,
        // attach_qr_code: 1,
      },
      guest_sign: true
    }
  },
  mounted() {
    this._get_appointment_info({
      secret_key: 'pKLIwH',
      // attach_qr_code: 1,
    })
    console.log('this.$route.params');
    console.log(this.$route.params);
  },
  methods: {
    _get_appointment_info() {
      get_appointment_info(this.params).then((res) => {
        this.content = res
      }).catch((err) => {
        console.log(err)
      })
    },
    _sign_appointment() {
      sign_appointment(this.params).then((res) => {
        // this.content = res
        this.content.sign_status = 1
        // this.guest_sign = !this.guest_sign
      }).catch((err) => {
        console.log(err)
      })
    },
  }
}
</script>
<style scoped>
.guestInfo {
  width: 327px;
  /* height: 374px; */
  background: #ffffff;
  box-shadow: 0px 0px 8px 1px rgba(204, 204, 204, 0.5);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  margin: auto;
  overflow: hidden;
  padding: 0 24px;
  padding-top: 32px;
  margin-top: 37px;
  box-sizing:border-box;
  position: relative;
}
.titleImg{
  width: 100%;
  margin-bottom: 17px;
}
.common_p{
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  line-height: 24px;
  margin-bottom: 24px;
}
.common_span{
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
}
.buttonConfig{
  width: 328px;
  height: 44px;
  background: #4492D2;
  border-radius: 44px 44px 44px 44px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 16px;
}
.configTro{
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
  line-height: 16px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.sign1{
  position: absolute;
  right: 0;
  bottom: 7px;
  width: 80px;
  height: 80px;
  display: block;
}
</style>
