import axios from 'axios'
// eslint-disable-next-line no-unused-vars
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  headers: {
    'Content-Type': 'application/json'
  }
  // withCredentials: true, // send cookies when cross-domain requests
})
// request interceptor
service.interceptors.request.use(
  config => {
    // if (getToken()) {
    //   config.headers['Authorization'] = getToken()
    // }
    return config
  }, error => {
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  response => {
    if (response.data.code === 1002) {
      return response.data.data
    } else if (response.data.code === 1004) {
      // Message.error(response.data.message)
      return Promise.reject(response.data)
      // eslint-disable-next-line eqeqeq
    } else if (response.data.code == 1403) {
      // Message.error(response.data.message)
      return Promise.reject(response.data)
      // eslint-disable-next-line eqeqeq
    } else if (response.data.code == 1401) {
      const key = sessionStorage.getItem('login-key')
      if (!key) {
        sessionStorage.setItem('login-key', '1')
        // MessageBox.alert('登录状态已过期，请重新登录!', '登录过期', {
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     store.dispatch('user/resetToken')
        //   }
        // })
      }
    } else {
      return response.data
    }
  },
  error => {
    if (error.request.status === 500) {
      // Message.error(error.request.statusText)
    } else {
      // Message.error(error.request.message)
    }
    return Promise.reject(error)
  }
)
export default service
