<template>
  <div class="home">
    <div class="info">
      <h3>WELCOME TO VISIT</h3>
      <h3>{{ content.engine_room_name_en }}</h3>
      <h3>DATA CENTER</h3>
      <h2 class="vist">尊敬的{{ content.user_name }} 先生/女士</h2>
      <h4>欢迎您莅临参观</h4>
      <h4>{{ content.engine_room_name }}</h4>
    </div>
    <div class="bottom">
      <div class="read" @click="handle">阅读保密协议</div>
    </div>
    <!-- 保密协议弹窗 -->
    <div class="confidentiality" v-show="isConfidentiality">
      <div class="content">
        <div class="title">保密协议</div>
        <div class="bmxy">
          <div class="bmxyCon">
            <p>
              您已申请进入广东云下汇金科技有限公司或其下属子公司（以下简称“云下科技”）运营管理的数据中心，凡进入云下科技运营管理的数据中心人员，需遵循如下保密协议：
            </p>
            <p>
              <b> 第一条、</b
              >本协议约定的保密信息是指您在进入数据中心期间获得的云下科技没有予以公开的信息，包括但不限于：
            </p>
            <p>
              1)云下科技的业务流程、业务数据、客户信息、产品服务定价、市场分析等经营信息；
            </p>
            <p>
              2)云下科技的机房设备配置信息、密码、知识产权、产品设计、计算机程序、技术数据等技术信息；
            </p>
            <p>
              3)云下科技的内部规章制度、组织架构、财务信息、人事资料、工资薪酬等管理信息。
            </p>
            <p>
              <b>第二条、</b
              >上述保密信息有以下情形的，不属于本协议的保密范围内：
            </p>
            <p>1)通过公众渠道可以获悉的信息，但因您的行为所引致的除外；</p>
            <p>2)经云下科技书面同意可以披露的信息；</p>
            <p>3)您合法自其它有权提供或披露资料之第三人处取得或知悉的信息。</p>
            <p>
             <b>第三条、</b>承诺不会以任何形式泄露、告知、公布、出版、传授、转让或使第三方知悉云下科技的保密信息。
            </p>
            <p>
              <b>第四条、</b>进入数据中心后，不得拍照、摄像或采取其他方式记录信息，未经云下科技书面许可不得擅自对任何设施设备进行任何操作。
            </p>
            <p>
              <b>第五条、</b>如违反本保密协议，应当立即停止侵害，并在第一时间采取一切必要措施防止保密信息的扩散，尽最大可能消除影响。云下科技保留依法追究法律责任的权利。
            </p>
            <p>
              <b>第六条、</b>本协议适用中华人民共和国法律，如双方发生争议且无法协商一致，您同意纠纷将提交广州仲裁委员会仲裁解决。
            </p>
          </div>
          <div class="bmxyButton">
            <div class="common_btn" @click="handle">取消</div>
            <div class="common_btn" @click="handleJump">同意</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { home } from '@/api/index'
export default {
  data() {
    return {
      isConfidentiality: false,
      content: {}
    }
  },
  mounted() {
    this._home({
      // secret_key: 'pKLIwH',
      secret_key: this.$route.params.userId,
      attach_qr_code: 0,
    })
  },
  methods: {
    handle() {
      this.isConfidentiality = !this.isConfidentiality
      // this,$router.push('/')
    },
    handleJump() {
      this.isConfidentiality = !this.isConfidentiality
      let userId = this.$route.params.userId
      this.$router.push({ path: '/visit/result/' + userId })
    },
    _home(prams) {
      home(prams).then((res) => {
        this.content = res
        this.content.engine_room_name_en = res.engine_room_name_en.toUpperCase()
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>
.home {
  /* height: 638px; */
  /* background: url("../assets/bg.png") no-repeat; */
  /* background-size: 100%; */
  /* position: relative; */
  /* padding: 0 24px; */
  box-sizing: border-box;
  padding-top: 88px;
}


.bottom {
  /* width: 375px;
  height: 341px; */
  position: absolute;
  bottom: 116px;
}
.read {
  width: 160px;
  height: 44px;
  background: #4492d2;
  border-radius: 44px 44px 44px 44px;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
h3 {
  font-size: 26px;
  
  font-weight: bold;
  color: rgba(0, 0, 0, 0.25);
  line-height: 34px;
}
h4 {
  font-size: 30px;
  
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
}
.confidentiality {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 327px;
  height: 534px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  padding: 0 24px;
  box-sizing: border-box;
  text-align: center;
  /* display: flex; */
  /* justify-content: center; */
}
.title {
  height: 58px;
  line-height: 58px;
  font-weight: 700;
  font-size: 18px;
  
}
.bmxyCon {
  /* padding: 0 24px; */
}
.bmxyCon {
  width: 279px;
  height: 406px;
  overflow: scroll;
  font-size: 12px;
  
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
  line-height: 22px;
  /* background: #C4C4C4; */
}
.info {
}
.vist {
  font-weight: 600;
  font-size: 18px;
  margin: 22px 0 4px;
  color: rgba(0, 0, 0, 0.75);
}
p {
  text-align: left;
}
.bmxyButton{
  display: flex;
  justify-content: space-around;
}
.common_btn {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #4492d2;
}
</style>
